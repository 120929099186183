import { combineReducers } from 'redux'
import content from './contentReducer'

export const initialState = {}

export const rootReducer = combineReducers({
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
  // nav: nav,
  content: content
})

import React, {useState, useEffect} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  Link,
} from "react-router-dom";
import {motion, useAnimation} from 'framer-motion'
import styled from 'styled-components'
import VideoFill from './VideoFill'
import { TitleXSmall, TitleSmall, TitleMedium, GridItemStyled, CenterLeftColumn, colors } from './SharedStyles'



function TitleCard({children, small, ...props}) {
  return(
    <motion.div
      style={{
        y:50,
        opacity:0,
        display:'flex', flexDirection:'column', textAlign:'center', alignItems:'center'
      }}
      animate={{
        y:0,
        opacity:1,
        transition: {
          ease:'easeOut',
          duration:.25 ,
        }
      }}
      whileHover={{
        color:colors.highlight,
        transition: {
          ease:'easeOut',
          duration:1 ,
        }
      }}
    >
      { children }
    </motion.div>
  )
}


function GridItem({item, i, ...props}) {
  const [ active, setActive ] = useState(false)

  const variants = {
    hidden: {
      opacity: 0,
      y: 300,
      height: 150,
    },
    visible: {
      opacity: 1,
      y:0,
      height:'100%',
      backgroundColor:colors.block,
      transition: {
        delay:0.1 * i,
        duration: 1,
        ease:'easeOut'
      },
    },

  }

  const variantsInner = {
    hidden: {
      scale:2,
      // height:380,
      y:-400,
    },
    visible: {
      scale:1.2,
      // height:300,
      y:0,
      opacity: 1,
      transition: {
        // delay:0.06 * i,
        duration: 1,
        ease:'easeOut'
      },
    },
    mouseOver: {
      scale:1,
      opacity: 0.3,
      transition: {
        duration: 2,
        ease:'easeOut'
      },
    }
  }

  return(
    <GridItemStyled>
      <motion.div
        style={{
          overflow:'hidden',
          position:'relative',
        }}
        custom={i}
        animate="visible"
        variants={variants}
      >
        <motion.div
          style={{
            width:'100%',
            height:'100%',
            overflow:'hidden',
            position:'absolute',
            backgroundImage:`url(${props.webPSupported ? process.env.PUBLIC_URL + item.cover.webp.medium : process.env.PUBLIC_URL + item.cover.jpg.medium})`,
            backgroundSize:'cover',
            backgroundPosition:'center center',

          }}
          custom={i}
          animate={active ? 'mouseOver' : 'visible'}
          variants={variantsInner}
          onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}
        >
          {active &&
            <VideoFill muted={true} url={'https://vimeo.com/' + item.vimeoId}>

            </VideoFill>
          }
        </motion.div>
        <div style={{
          position:'absolute',
          left:0, top:0, height:'100%', width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        }}
        onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}
        >
          { active &&
            <TitleCard>
              <TitleXSmall>{item.title2}</TitleXSmall>
              <TitleMedium>{item.title1}</TitleMedium>
            </TitleCard>
           }
        </div>
      </motion.div>
    </GridItemStyled>
  )
}

function Grid({items, ...props}) {

  if(!items) return null

  const list = {
    visible: {
      opacity: 1,
      transition: {
        // when: "beforeChildren",
        // staggerChildren: 1.5,
        // duration: 300
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        // when: "afterChildren",
      },
    },
  }
  return(
    <motion.div
      style={{
        padding:4,
      }}
      initial="hidden"
      animate="visible"
      variants={list}
    >

    {
      items.map((item, i) => (
        <Link key={i} to={'/films/' + item.id}>
          <GridItem variants={list} i={i} item={item} webPSupported={props.webPSupported}/>
        </Link>
      ))
    }
    </motion.div>
  )

}


const mapStateToProps = (state, ownProps) => {
  return {
    items: state.content.db.items,
    webPSupported: state.content.webPSupported
  }
}

export default compose(
  connect(mapStateToProps),
)(Grid)

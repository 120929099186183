import React, {Component, useEffect, useState} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import {motion} from 'framer-motion'
import { initDatabase, setWebPSupported } from './redux/actions';

import './App.css';

import styled from 'styled-components'
import Header from './Header'
import Films from './Films'
import Loader from './Loader'
import Grid from './Grid'
import Contact from './Contact'
import VideoFill from './VideoFill'
import TrailerTitles from './TrailerTitles'
import { Main, TitleLarge, TitleMedium, TitleSmall, CenterLeftColumn, colors } from './SharedStyles'
// import { db } from './db'
import db from './db-processed';

import { check_webp_feature } from './utilityFunctions.js'


document.body.style.backgroundColor = colors.background


const Master = styled.div({
  fontFamily:'GZA',
  '@media (max-width: 768px)': {
  },
  ':hover': {
  }
})
const TrailerContainer = styled.div({
  // width:'calc(100% - 8px)',
  // margin:4,
  // height:800,
  position:'relative',
  left:0,
  top:0,
  width:'100%',
  height:'100vh',
  // maskImage: `linear-gradient(to top, transparent 10%, ${colors.background} 100%)`,
  '@media (max-width: 768px)': {
  },
  ':hover': {
  }
})





class App extends Component {

  // const [ trailerProgress, setTrailerProgress ] = useState({playedSeconds:0})
  // const [ trailerReady, setTrailerReady ] = useState(false)

  constructor(props) {
    super(props)
    this.state = {
      trailerProgress: {playedSeconds:0},
      trailerReady: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const i = import('./font.js')

    this.props.initDatabase(db)

    // Check if webp is supported
    check_webp_feature('lossy', (feature, isSupported) => {

      if (isSupported) {
        this.props.setWebPSupported(true)
      }
      else {
        this.props.setWebPSupported(false)
      }
    })

  }

  setTrailerProgress = (progress) => {
    this.setState({trailerProgress:progress})
  }

  setTrailerReady = () => {
    this.setState({trailerReady:true})
  }

  render() {

    return (
      <Master>
      <Router onUpdate={() => window.scrollTo(0, 0)}>

          <Header/>

          <Switch>

            <Route path={'/films'}>
              <Films />
            </Route>

            <Route path={'/contact'}>
              <Contact />
            </Route>

            <Route path={'/'}>
              <>
              {!this.state.trailerReady && false
                && <Loader />
              }
                <motion.div
                  style={{
                    opacity:0,
                  }}
                  animate={{
                    opacity: this.state.trailerReady ? 1 : 0,
                    transition: {
                      ease:'easeOut',
                      duration:2 ,
                      delay:0.5
                    }
                  }}
                >
                  <TrailerContainer>
                    <VideoFill onReady={this.setTrailerReady} onProgress={this.setTrailerProgress} loop url={'https://vimeo.com/391068180'} muted={true} playing={true} />
                    <TrailerTitles trailerProgress={this.state.trailerProgress}/>
                  </TrailerContainer>

                </motion.div>
                <div style={{marginTop:'-20vh'}}>
                  <Main>
                    <Grid />
                  </Main>
                </div>

              </>
            </Route>
          </Switch>

      </Router>
      </Master>
    )
  }
}




const mapStateToProps = (state, ownProps) => {

  return {
    db: state.content.db
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initDatabase: (db) => {
      dispatch(initDatabase(db))
    },
    setWebPSupported: (isSupported) => {
      dispatch(setWebPSupported(isSupported))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(App)

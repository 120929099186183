import {
  INIT_DATABASE,
  SET_WEBP_SUPPORTED
} from './actions'

export default function content(state = { db:{}, webPSupported:true }, action) {

    switch (action.type) {
      case INIT_DATABASE:
        return Object.assign({}, state, { db: action.db })
      case SET_WEBP_SUPPORTED:
        return Object.assign({}, state, { webPSupported: action.isSupported })
      default:
        return state
      }
    }


// thumb:process.env.PUBLIC_URL + '/images/films/filmId/cover/1.webp',

var items = [
  {
    id:'gradifi',
    vimeoId:'245382903',
    title1: 'Sophie',
    title2: 'Gradifi',
  },
  {
    id:'armedforces',
    vimeoId:'239437162',
    title1: 'In Peace',
    title2: 'Swedish Armed Forces',
  },
  {
    id:'hm',
    vimeoId:'277116438',
    title1: 'Calling You',
    title2: 'H&M',
  },
  {
    id:'comhem',
    vimeoId:'321997476',
    title1: 'The Hike',
    title2: 'Com Hem',
  },
  {
    id:'samsung',
    vimeoId:'215206734',
    title1: 'Invest in the Impossible',
    title2: 'Samsung',
  },
  {
    id:'whirlpool',
    vimeoId:'267494751',
    title1: 'Congrats Parents',
    title2: 'Whirlpool',
  },
  {
    id:'lantmannen',
    vimeoId:'388217727',
    title1: 'From Field to Fork',
    title2: 'Lantmannen',
  },
  {
    id:'spotify',
    vimeoId:'128416321',
    title1: 'Now',
    title2: 'Spotify',
  },
  {
    id:'thisissweden',
    vimeoId:'153204210',
    title1: 'This Is Sweden',
    title2: 'Swedish Institute',
  },
  {
    id:'nikeraha',
    vimeoId:'152711421',
    title1: 'Raha',
    title2: 'Nike',
  },
  {
    id:'academicwork',
    vimeoId:'297273214',
    title1: 'Growth',
    title2: 'Academic Work',
  },
  {
    id:'stadsmission',
    vimeoId:'225869505',
    title1: 'Sommarlov',
    title2: 'Stockholms Stadsmission',
  },
  {
    id:'skandia',
    vimeoId:'388217684',
    title1: 'Nytt Jobb',
    title2: 'Skandia',
  },
  {
    id:'nikeamna',
    vimeoId:'152712515',
    title1: 'Amna',
    title2: 'Nike',
  },
  {
    id:'nikefatma',
    vimeoId:'152712607',
    title1: 'Fatma',
    title2: 'Nike',
  },
  {
    id:'boplay',
    vimeoId:'313384457',
    title1: 'Love',
    title2: 'B&O Play / Ford',
  },
  {
    id:'skoda',
    vimeoId:'230449663',
    title1: 'A Different Path',
    title2: 'Skoda',
  },
  {
    id:'fmtk',
    vimeoId:'158013031',
    title1: 'FMTK',
    title2: 'Swedish Armed Forces',
  },
  {
    id:'vattenfall',
    vimeoId:'163227906',
    title1: 'Vattenfall',
    title2: 'Well Done Sun',
  },
  {
    id:'freak',
    vimeoId:'32068020',
    title1: 'Urban Cone',
    title2: 'Freak',
  },
]



// items = items.map(item => {
//   var files = require.context(item.thumbFolder, false, /.*\.jpg$/)
//   return {
//     ...item, thumbs:files
//   }
// })

const db = {
  items
}

// export { db }

module.exports = { db }

import { createStore, compose } from 'redux'
import { initialState, rootReducer } from './reducers'

const enhancers = [
]

const reduxDevToolsExtension = window.devToolsExtension
if (
  process.env.NODE_ENV === "development" &&
  typeof reduxDevToolsExtension === "function"
) {
  enhancers.push(reduxDevToolsExtension())
}

const composedEnhancers = compose(
  ...enhancers
)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store

import React, {useState} from 'react'
import {
  Link,
} from "react-router-dom";

import styled from 'styled-components'
import {motion} from 'framer-motion'
import { colors, TitleSmall, TitleXSmall } from './SharedStyles'

const contact = [
  {
    title: 'Commercials',
    contacts: [
      {
        territory: 'US',
        company: {
          name: 'BR—F',
          link: 'https://brf.co'
        },
        person: {
          name: 'Michael McQuhae',
          link: 'mailto:michael@brf.co'
        }
      },
      {
        territory: 'Scandinavia',
        company: {
          name: 'BR—F',
          link: 'https://brf.co'
        },
        person: {
          name: 'Martin Ronstrom',
          link: 'mailto:martin@brf.co'
        }
      },
      {
        territory: 'France',
        company: {
          name: 'Iconoclast',
          link: 'https://iconoclast.tv'
        },
        person: {
          name: 'Domitille Laurent',
          link: 'mailto:domitille@iconoclast.tv'
        }
      },
      {
        territory: 'Germany',
        company: {
          name: 'Tempomedia',
          link: 'https://www.tempomedia.de'
        },
        person: {
          name: 'Justin Mundhenke',
          link: 'mailto:justin@tempomedia.de'
        }
      },
    ]
  },
  {
    title: 'Music Videos',
    contacts: [
      {
        territory: 'US',
        company: {
          name: 'Las Bandas',
          link: 'https://lasbandas.tv'
        },
        person: {
          name: 'Jen Herrera',
          link: 'mailto:jen@lasbandas.tv'
        }
      },
      {
        territory: 'France',
        company: {
          name: 'Iconoclast',
          link: 'https://iconoclast.tv'
        },
        person: {
          name: 'Domitille Laurent',
          link: 'mailto:domitille@iconoclast.tv'
        }
      },
    ]
  }
]

const Main = styled.div((props) => ({
  // backgroundColor:'#B30000',

  height:'100%',
  width:'100%',
  position:'fixed',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  top:0,
  left:0,

}))

const Info = ({children, ...props}) => (
<motion.div
    style={{
      color:colors.title,
      fontSize:50,
      textAlign:'center',
      width:'90%',
      maxWidth:1000,
      opacity:0,
      y:200,
    }}
    animate={{
      opacity:1,
      y:0,
      transition: {
        ease:'easeOut',
        duration:1 ,
      }
    }}
    whileHover={{
      color:colors.highlight,
      transition: {
        ease:'easeOut',
        duration:1 ,
      }
    }}
  >
    { children }
  </motion.div>
)

const Area = styled.div((props) => ({
  marginTop:64,
  '@media (max-width: 768px)': {
  },
  ':hover': {
  }
}))

const Contacts = styled.div((props) => ({
  marginTop:32,
  '@media (max-width: 768px)': {
  },
  ':hover': {
  }
}))

const Row = styled.div((props) => ({
  width:'100%',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  '@media (max-width: 800px)': {
  },
  ':hover': {
    color:'#fff'
  }
}))

const Column = styled.div(({right, left, hideOnMobile, alignOnMobile}) => ({
  flex:'1 1 0',
  margin:'2px 8px 2px 8px',
  textAlign: left ? 'left' : right ? 'right' : 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '@media (max-width: 800px)': {
    display: hideOnMobile && 'none',
    textAlign: alignOnMobile && alignOnMobile,
  },
  ':hover': {
    color:'#fff',
  }
}))

function Contact(contact) {

  if(!contact.company) console.log('contact', contact, contact.company)


  return(
    <Row>
      <Column right>
        <TitleXSmall>{contact.territory}</TitleXSmall>
      </Column>
      <Column alignOnMobile={'left'}>
        <a style={{color:'inherit', textDecoration:'none'}} href={contact.company.link} target={'_blank'}>
          <TitleXSmall>
            {contact.company.name}
          </TitleXSmall>
        </a>
      </Column>
      <Column left hideOnMobile>
        <a style={{color:'inherit', textDecoration:'none'}} href={contact.person.link} target={'_blank'}>
          <TitleXSmall>{contact.person.name}</TitleXSmall>
        </a>
      </Column>
    </Row>
  )
}

function ContactPage() {
  return (
    <Main>
      <Info>
        {
          contact.map(area => (
            <Area key={area.title}>
              <TitleSmall>{area.title}</TitleSmall>
              <Contacts>
              {area.contacts.map(contact => (
                <Contact {...contact} />
              ))}
              </Contacts>
            </Area>
          ))
        }
      </Info>
    </Main>
  )
}


export default ContactPage

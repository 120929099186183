import React from 'react'
import { Link } from "react-router-dom";
import { Main, TitleLarge, TitleMedium, TitleSmall, CenterRightColumn, colors } from './SharedStyles'

import styled from 'styled-components'


const HeaderOuter = styled.div({
  position:'fixed',
  left:0,
  top:0,
  zIndex:10,
  width:'calc(100%)',

  '@media (max-width: 1000px)': {
  },
  ':hover': {
  }
})

const HeaderInner = styled.div({
  color:'#fff',
  position:'relative',
  margin:'32px 64px 32px 64px',
  width:'calc(100% - 128px)',
  color:'#fff',
  '@media (max-width: 1000px)': {
    margin:'32px 32px 32px 32px',
    width:'calc(100% - 64px)',
  },
  ':hover': {
  }
})

const HeaderItem = styled.div((props) => ({
  fontSize:16,
  display:'inline-block',
  color:colors.title,
  // margin:props.tight ? '0 16px 0 16px' : '0 64px 0 64px',
  margin:props.withMargin ? '0 16px 0 16px' : '0 0px 0 0px',
  float:props.left ? 'left' : props.right ? 'right' : 'default',
  '@media (max-width: 1000px)': {
    margin:0,
    display:props.hideOnMobile && 'none',
    fontSize:16,
  },
  ':hover': {
    color: colors.highlight
  }
}))




function Header() {
  return (
    <HeaderOuter>
      <HeaderInner>
      <Link to={'/'}><HeaderItem left>Olof Lindh</HeaderItem></Link>

      <div style={{width:'40%', position:'absolute', fontSize:0, textAlign:'center', left:'55%'}}>
        <Link to={'/'}><HeaderItem hideOnMobile withMargin>Film</HeaderItem></Link>
        <a href={'http://instagram.com/olof.lindh'} target={'_blank'}><HeaderItem hideOnMobile withMargin>Photography</HeaderItem></a>
        <HeaderItem hideOnMobile withMargin>Special Projects</HeaderItem>

      </div>

      <Link to={'/contact'}><HeaderItem right>Contact</HeaderItem></Link>
      </HeaderInner>
    </HeaderOuter>
  )
}

export default Header

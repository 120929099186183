import React, {useState, useEffect} from 'react'
import loadingVideoSmall from './videos/Loop_Stills_Smaller.mp4'
import {motion, useAnimation} from 'framer-motion'
import styled from 'styled-components'

import ReactPlayer from 'react-player'
import { colors } from './SharedStyles'

const Container = styled.div((props) => ({
  background: colors.background,
  position:'absolute',
  zIndex:3,
  width:'100%',
  height:'100vh',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  '@media (max-width: 1000px)': {
    fontSize:38,
  },
  '@media (max-width: 800px)': {
    fontSize:30,
  },
  ':hover': {
  }
}))

function Loader() {
  return(
    <Container>
      <motion.div
        initial={{scale:0}}
        animate={{scale:2}}
        transition={{duration:10, ease:'easeOut'}}
      >
        <ReactPlayer playing={true} loop url={loadingVideoSmall}/>
      </motion.div>
    </Container>
  )
}

export default Loader

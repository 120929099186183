import React from 'react'
import { useRouteMatch, useParams, Switch, Route } from "react-router-dom";

import Film from './Film'
import Grid from './Grid'
// import { db } from './db'
// import items from './db-processed';


function Films({db, ...props}) {

  let match = useRouteMatch();

  return (
    <Switch>

      <Route path={`${match.path}/:filmId`}>
        <FilmPage/>
      </Route>

      <Route path={match.path}>
        <Grid />
      </Route>

    </Switch>
  )
}

export default Films

function FilmPage({db}) {
  let { filmId } = useParams();
  return <Film filmId={filmId}/>
}

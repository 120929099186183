import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { Main, TitleLarge, TitleMedium, TitleSmall, colors } from './SharedStyles'
import styled from 'styled-components'
import { motion, AnimatePresence } from "framer-motion"
import { db } from './db'

const TitleCard = styled.div({
  textAlign:'right',
  position:'absolute',
  right:0,
  zIndex:5,
  // right:60,
  boxSizing:'border-box',
  bottom:'calc(20vh + 64px)',
  ':hover': {
  },
  '@media (max-width: 1000px)': {
    right:16
  },
})



const Overlay = styled.div({
  width:'100%',
  position:'absolute',
  height:'100vh',
  left:0,
  top:0,
  background:`linear-gradient(180deg, rgba(0,0,0,0) -50%, ${colors.background} 90%)`,
})

function TrailerTitles({trailerProgress}) {

  let timeCodes = [
    {
      time:0,
      id:'hm'
    },
    {
      time:6.6,
      id:'armedforces'
    },
    {
      time:11.4,
      id:'gradifi'
    },
    {
      time:17.9,
      id:'samsung'
    }
  ]


  let chosen = {}
  timeCodes.forEach(item => {
    if(trailerProgress.playedSeconds >= item.time) chosen = item
  })

  const { title1, title2 } = db.items.filter(item => item.id === chosen.id)[0]
  chosen = { ...chosen, title1, title2 }

  return (
    <Link to={'/films/' + chosen.id}>
      <Overlay/>
        <Main>
          <TitleCard>
            <Animated key={chosen.id}>
              <TitleSmall>{chosen.title2}</TitleSmall>
              <TitleLarge>{chosen.title1}</TitleLarge>
            </Animated>
          </TitleCard>
        </Main>
    </Link>

  )
}


const Animated = ({ key, isVisible, children }) => (
  <AnimatePresence>

      <motion.div
        key={key}
        style={{
          color:colors.title,
          opacity: 0
        }}
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease:'easeOut',
          duration:2
        }}
        whileHover={{
          color:colors.highlight,
          transition: {
            ease:'easeOut',
            duration:1 ,
          }
        }}
        exit={{ opacity: 0, y: -200 }}
      >

          {children}

      </motion.div>

  </AnimatePresence>
)

export default  TrailerTitles

export const INIT_DATABASE = 'INIT_DATABASE'
export const SET_WEBP_SUPPORTED = 'SET_WEBP_SUPPORTED'

export function initDatabase(db) {
  return { type: INIT_DATABASE, db }
}

export function setWebPSupported(isSupported) {
  return { type: SET_WEBP_SUPPORTED, isSupported }
}

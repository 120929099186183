import React, {useState, useEffect} from 'react'
import Grid from './Grid'
import { GridItemStyled } from './SharedStyles'
import {motion} from 'framer-motion'
import styled from 'styled-components'

const FramesContainer = styled.div({
  position:'relative',
  width:'100%',
  paddingTop:60,
  paddingBottom:120,
  '@media (max-width: 1000px)': {
  },
  ':hover': {
  }
})

function Frames({frames}) {

  return (
    <FramesContainer>
    {
      frames.map(frame => {
        return(
          <GridItemStyled>
            <motion.div
              style={{
                width:'100%',
                height:'100%',
                backgroundImage:`url(${process.env.PUBLIC_URL + frame})`,
                backgroundSize:'contain',
                backgroundRepeat:'no-repeat',
                backgroundPosition:'center center',
              }}
            />
          </GridItemStyled>
        )
      })
    }
    </FramesContainer>
  )


}


Frames.defaultProps = {
  frames: []
}

export default Frames

import React from 'react'
import styled from 'styled-components'

// const titleColor = '#FF0048'

let colors = {
  // blue: {
  //   title:'#2F00FF',
  //   block:'#020029',
  //   background:'#000'
  // },
  green: {
    title:'#00FF55',
    block:'#00140F',
    background:'#00140F',
  },
  // sand: {
  //   title:'#00FF55',
  //   block:'#00140F',
  //   background:'#00140F',
  // },
  noCountry1: {
    title:'#BC770C',
    block:'#01100B',
    background:'#01100B',
  },
  noCountry2: {
    title:'#DC9E47',
    block:'#000812',
    background:'#000812',
    highlight:'#7C1B07'
  },
  noCountry3: {
    title:'#7C1B07',
    block:'#061314',
    background:'#061314',
  },


}

// colors = {...colors[Object.keys(colors)[Math.floor(Math.random() * Object.keys(colors).length)]], ...colors}
colors = {...colors.noCountry2, ...colors}

const Main = styled.div({
  position:'relative',
  color:colors.title,
  fontSize: 0, // this is needed to remove the white space between grid items
  width:'calc(100% - 56px * 2)',
  margin:56,
  paddingTop:60,
  boxSizing:'border-box',
  // backgroundColor:'#000',
  '@media (max-width: 1000px)': {
    width:'calc(100% - 0px * 2)',
    margin:0,
    paddingTop:60,
    // paddingTop:4,
  },
  ':hover': {
  }
})

const GridItemStyled = styled.div({

  color:colors.title,
  width:'calc(50% - 8px)',
  display:'inline-block',
  margin:4,
  // height:300,
  height:'calc((50vw - 144px) / 1.77)',
  maxHeight:'50vh',
  '@media (max-width: 1000px)': {
    width:'calc(100% - 8px)',
    height:'calc((100vw) / 1.77)',
  },
  ':hover': {
  }
})

const TitleLarge = styled.div({
  fontSize:180,
  fontFamily:'GZA-italic',
  // color:colors.title,
  '@media (max-width: 1000px)': {
    fontSize:120,
  },
  '@media (max-width: 800px)': {
    fontSize:64,
  },
  ':hover': {
  }
})

const TitleMedium = styled.div({
  fontSize:56,
  fontFamily:'GZA-italic',
  // color:colors.title,
  '@media (max-width: 1000px)': {
    fontSize:40,
  },
  '@media (max-width: 800px)': {
    fontSize:38,
  },
  ':hover': {
  }
})

const TitleSmall = styled.div((props) => ({
  fontSize:36,

  // color:colors.title,
  '@media (max-width: 1000px)': {
    fontSize:34,
  },
  '@media (max-width: 800px)': {
    fontSize:24,
  },
  ':hover': {
  }
}))


const TitleXSmall = styled.div((props) => ({
  fontSize:20,
  // color:colors.title,
  '@media (max-width: 1000px)': {
    fontSize:18,
  },
  '@media (max-width: 800px)': {
    fontSize:14,
  },
  ':hover': {
  }
}))

const CenterLeftColumn = styled.div({
  width:'50%',
  textAlign:'center',
  '@media (max-width: 1000px)': {
    width:'100%',
  },
  ':hover': {
  }
})

const CenterRightColumn = styled.div({
  width:'50%',
  left:'50%',
  textAlign:'center',
  '@media (max-width: 1000px)': {
    width:'100%',
  },
  ':hover': {
  }
})


export { Main, TitleLarge, TitleMedium, TitleSmall, TitleXSmall, GridItemStyled, CenterRightColumn, CenterLeftColumn, colors }

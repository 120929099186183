import React, {useState, useEffect, useRef} from 'react'
import ReactPlayer from 'react-player'
import { SizeMe, withSize } from 'react-sizeme'


function VideoFill({children, size, url, ...props}) {

  const [videoSize, setVideoSize] = useState({width:0,height:0})
  const playerRef = useRef(null);

  function _handleReady(e) {
    const player = e.getInternalPlayer()

    const { width, height } = player.element
    setVideoSize({width, height})

    props.onReady()

  }

  function _handleEnded() {
    playerRef.seekTo(0)
    props.onClick()
  }

  function _handleClick() {
    props.onClick()
  }

  function getPlayerSize(videoSize, wrapperSize) {

    const videoAspect = videoSize.width / videoSize.height
    const wrapperAspect = wrapperSize.width / wrapperSize.height

    const ratio = videoAspect / wrapperAspect

    // If NaN
    if(!ratio) return {width:0,height:0}

    var width = 0
    var height = 0

    if(ratio > 1) {
      height = wrapperSize.height
      width = height * videoAspect
    }
    else {
      width = wrapperSize.width
      height = width / videoAspect
    }
    return { height, width }
  }


  console.log('playing', props.playing)

  return (

    <div style={{width:'100%', display:'inline-flex', alignItems:'center', justifyContent:'center', height:'100%', overflow:'hidden'}}>

      <ReactPlayer
            ref={playerRef}
            onError={(e) => console.log('Error', e)}
            playing={props.playing}
            style={{width: '100%', height:'100%', position:'absolute', overflow:'hidden'}}
            onReady={_handleReady}
            loop={props.loop}
            playsinline
            width={getPlayerSize(videoSize, size).width}
            height={getPlayerSize(videoSize, size).height}
            url={url}
            muted={props.muted}
            onEnded={_handleEnded}
            vimeo={{allow:"autoplay"}}
            onProgress={props.onProgress}
            progressInterval={30}
          >

      </ReactPlayer>


      <div
        style={{
          // background:'rgba(0,0,0,.7)',
          position:'absolute',
          width:'100%',
          height:'100%',
          left:0,
          top:0,
          display:'inline-flex', alignItems:'center', justifyContent:'center'
        }}
        onClick={_handleClick}
      >
      {children}
      </div>



    </div>

  )
}

VideoFill.defaultProps = {
  playing: true,
  muted: false,
  loop: true,
  onProgress: () => {},
  onReady: () => {},
  onClick: () => {}
}


export default withSize({monitorHeight:true})(VideoFill)

import React, {useState, useEffect} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  Link,
  useParams
} from "react-router-dom";

import ReactPlayer from 'react-player'
import styled from 'styled-components'
import {motion} from 'framer-motion'

// import { db } from './db'

import cursor_play from './graphics/cursor_play.svg'
import cursor_pause from './graphics/cursor_pause.svg'

import VideoFill from './VideoFill'
import Films from './Films'
import Frames from './Frames'
import { Main, TitleMedium, TitleLarge, TitleSmall, TitleXSmall, CenterLeftColumn, CenterRightColumn, colors } from './SharedStyles'

const PlayerContainer = styled.div({
  position:'relative',
  height:'100vh',
  width:'100%',
  '@media (max-width: 1000px)': {
  },
  ':hover': {
  }
})


function TitleCard({children, onClick, small, paused, ...props}) {
  return(
    <motion.div
      onClick={onClick}
      style={{
        cursor:paused ? `url(${cursor_play}), auto` : `url(${cursor_pause}), auto`,
        color:colors.title,
        display:'flex',
        position:'absolute',
        left:'50%',
        top:'50%',
        width:'100%',
        height:100,
        marginLeft:'-50%',
        marginTop:-50,
        flexDirection:'column',
        textAlign:'center',
        alignItems:'center',
        opacity:0,
      }}
      animate={{
        opacity:1,
        transition: {
          ease:'easeOut',
          duration:.5 ,
        }
      }}
      whileHover={{
        color:colors.highlight,
        transition: {
          ease:'easeOut',
          duration:1 ,
        }
      }}
    >
      { children }
    </motion.div>
  )
}




function AnimatedTitle({playing, paused, small, hidden, onClick, children, ...props}) {

  const variants = {
    hidden: {
      y:200,
      opacity:0,
      cursor:'inherit',
      transition: {
        duration: 0
      },
    },
    paused: {
      opacity:1,
      y:0,
      // cursor:`url(${cursor_play}), auto`,
      transition: {
        ease: "easeOut",
        delay: 0,
        duration: 0.5
      },
    },
    playing: {
      scale:.9,
      opacity:0,
      // cursor: `url(${cursor_pause}), auto`,
      transition: {
        ease: "easeOut",
        delay: 0,
        duration: 0.25
      },
    },
  }

  return (
    <motion.div
      onClick={onClick}
      style={{

        // display:'flex',
        // alignItems:'center',
        // justifyContent:'center',

      }}
      initial='hidden'
      animate={hidden ? 'hidden' : paused ? 'paused' : 'playing'}
      variants={variants}
    >
      { small
        ?
          <TitleSmall>{children}</TitleSmall>
        :
          <TitleMedium>{children}</TitleMedium>
      }

    </motion.div>
  )
}

function Overlay({onClick, children, cover, hasStarted, ...props}) {

  const variants = {
    hidden: {
      opacity:0,
      transition: {
        ease: "easeOut",
        duration: 0.5
      },
    },
    visible: {
      opacity:1,
      transition: {
        ease: "easeOut",
        duration: 0.5
      },
    },
  }

  return (
    <motion.div
      onClick={onClick}
      style={{
        height:'100%',
        width:'100%',
        position:'absolute',
        left:0,
        top:0,
        backgroundImage:`url(${cover.medium})`,
        backgroundSize:'cover',
        backgroundPosition:'center center',
      }}
      initial='hidden'
      animate={hasStarted ? 'hidden' : 'visible'}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

function Film({filmId, items, ...props}) {

  // let { filmId } = useParams();
  const [ hidden, setHidden ] = useState(false)
  const [ paused, setPaused ] = useState(true)
  const [ hasStarted, setHasStarted ] = useState(false)
  const [ playing, setPlaying ] = useState(false)

  // If item changes, update
  useEffect(() =>  {
    window.scrollTo(0, 0)
    setHidden(true)
    setPaused(true)
    setHasStarted(false)

    setTimeout(() => {
      window.scrollTo(0, 0)
      setHidden(false)
    }, 100)
  }, [filmId])



  if(!items) return null

  const item = items.filter(item => item.id === filmId)[0]
  const cover = props.webPSupported ? item.cover.webp : item.cover.jpg

  function _handleReady() {

    setPlaying(true)

    setTimeout(() => {
      setPaused(false)
      setHasStarted(true)
      // setPlaying(true)

    }, 500)

  }

  function _handleClick() {
    if(playing || !paused) {
      setPaused(true)
      setPlaying(false)
    }
    else {
      setPaused(false)
      setTimeout(() => {
        setPlaying(true)
      }, 250)
    }
  }

  const variants = {
    hidden: {
      height:0,
      y:800,
      width:'calc(100% - 128px)',
      margin:64,
      marginTop:'12.5vh',
      // cursor:playing ? `url(${cursor_pause}), auto` : `url(${cursor_play}), auto`,
      // backgroundColor:'#00241C',
      // backgroundColor:'#B30000',
      transition: {
        duration:0
      }
    },
    paused: {
      // position:'relative',
      opacity:1,
      width:'calc(100% - 128px)',
      margin:64,

      y:0,
      height:'57.5vh',
      marginTop:'21.25vh',
      transition: {
        ease:'easeOut',
        duration:0.5
      }
    },
    playing: {
      opacity:1,
      backgroundColor:'#000',
      width:'100%',
      margin:0,

      marginTop:0,
      y:0,
      height:'100vh',
      transition: {
        ease: "easeOut",
        delay: 0,
        duration: 0.5
      },
    },
  }

  return (
    <>
      <PlayerContainer >

        <div style={{height:'100vh', width:'100%', position:'absolute'}}>
          <motion.div
            style={{cursor:paused ? `url(${cursor_play}), auto` : `url(${cursor_pause}), auto`,  position:'relative'}}
            initial='hidden'
            animate={hidden ? 'hidden' : paused ? 'paused' : 'playing'}
            variants={variants}
          >

            <motion.div
              style={{
                position:'absolute',
                width:'100%',
                height:'100%',
                left:0,
                top:0,
              }}
              variants={{
                started: {
                  opacity:1,
                  transition: {
                    ease: "easeOut",
                    delay: 0.5,
                    duration: 0.5
                  },
                },
                hidden: {
                  opacity:0
                }
              }}
              animate={hasStarted ? 'started' : 'hidden'}
            >
              <VideoFill onClick={_handleClick} url={'https://vimeo.com/' + item.vimeoId} onReady={_handleReady} playing={playing} />
            </motion.div>

            <Overlay onClick={_handleClick} cover={cover} item={item} hasStarted={hasStarted}/>


          </motion.div>
        </div>

        <TitleCard onClick={_handleClick} paused={paused}>
          <AnimatedTitle small onClick={_handleClick} hidden={hidden} paused={paused} playing={playing}>{item.title2}</AnimatedTitle>
          <AnimatedTitle onClick={_handleClick} hidden={hidden} paused={paused} playing={playing}>{item.title1}</AnimatedTitle>
        </TitleCard>

      </PlayerContainer>


      <Main>

        <CenterLeftColumn>
          <TitleMedium>Frames</TitleMedium>
        </CenterLeftColumn>

        <Frames frames={props.webPSupported ? item.frames.webp : item.frames.jpg }/>

        <CenterLeftColumn>
          <TitleMedium>More Films</TitleMedium>
        </CenterLeftColumn>

        <Films/>

      </Main>

    </>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    items: state.content.db.items,
    webPSupported: state.content.webPSupported
  }
}

export default compose(
  connect(mapStateToProps),
)(Film)
